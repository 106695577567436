import axios from 'axios';
import { Configuration } from './config';

const instance = axios.create();
instance.defaults.headers.common = {}

export default {
    async getDataEmpresaWarren(rut, token) {
        const formData = new FormData();
        formData.append('rut_empresa', rut);
        formData.append('token', token);

        const response = await instance.post(`${Configuration.api.base}/validarDatos`, formData);
        return { 
          status: response.status, 
          data: response.data.data 
        };
    },
    
    async enrolarEmpresaClienteWarren(payload) {
        const formData = new FormData();
        formData.append('rut', payload.rut);
        formData.append('clavesii', payload.clave_sii);
        formData.append('nombre', payload.nombre);
        formData.append('telefono', payload.telefono);
        formData.append('email', payload.email);
        formData.append('empresa_id', payload.empresa_id);
        formData.append('token', payload.token);
        formData.append('razon_social', payload.razon_social);

        const response = await instance.post(`${Configuration.api.base}/enrolarEmpresa`, formData);
        
        return response;
    },

    async getRubros() {
        try {
            const response = await instance.get(`${Configuration.api.base}/rubros`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    async createCompraInforme(payload) {
        const formData = new FormData();
        formData.append('rut', payload.rut);
        formData.append('name', payload.name);
        formData.append('lastname', payload.lastname);
        formData.append('email', payload.email);
        formData.append('telefono', payload.telefono);
        formData.append('tipo_informe', payload.tipo_informe);
        formData.append('monto_compra', payload.monto_compra);
        formData.append('cant_informes', payload.cant_informes);

        const response = await instance.post(`${Configuration.api.base}/crear-orden`, formData);
        return response.data;
    },

    async createPayment(payload) {
        const formData = new FormData();
        formData.append('orden_compra', payload.orden_compra);
        formData.append('monto', payload.monto);
        formData.append('descripcion', payload.descripcion);
        formData.append('email_cliente', payload.email_cliente);

        const response = await instance.post(`${Configuration.api.base}/crear-pago`, formData);
        return response.data;
    },

    async getStatePayment(token) {
        const formData = new FormData();
        formData.append('token', token);

        const response = await instance.post(`${Configuration.api.base}/estado-pago`, formData);
        return response.data;
    },

    async validateOrder(orden_encrypt) {
        const formData = new FormData();
        formData.append('token', orden_encrypt);

        const response = await instance.post(`${Configuration.api.base}/verificar-orden`, formData);
        return response.data;
    },

    async updateDataOrder(payload) {
        const formData = new FormData();
        formData.append('n_orden', payload.orden);
        
        for (const key in payload) {
            if (key !== 'orden') {
                formData.append(key, JSON.stringify(payload[key])); // Convertir a string si es necesario
            }
        }

        const response = await instance.post(`${Configuration.api.base}/actualizar-orden`, formData);
        return response.data;
    },

    async getDataEmpresaGuest(rut, token) {
        const formData = new FormData();
        formData.append('rut_empresa', rut);
        formData.append('token', token);

        const response = await instance.post(`${Configuration.api.base}/validar-datos`, formData);
        return { 
          status: response.status, 
          data: response.data.data 
        };
    },

    async enrolarEmpresaCliente(payload) {
        const formData = new FormData();
        formData.append('rut', payload.rut);
        formData.append('clavesii', payload.clave_sii);
        formData.append('nombre', payload.nombre);
        formData.append('telefono', payload.telefono);
        formData.append('email', payload.email);
        formData.append('empresa_id', payload.empresa_id);
        formData.append('token', payload.token);
        formData.append('razon_social', payload.razon_social);
        
        const response = await instance.post(`${Configuration.api.base}/enrolar-cliente`, formData);
        return response;
    },

    async cancelPayment(payload) {
        const formData = new FormData();
        formData.append('n_orden', payload);

        const response = await instance.post(`${Configuration.api.base}/cancelar-orden`, formData);
        return response.data;
    },

    async autoCompleteFields(payload) {
        const formData = new FormData();
        for (const key in payload) {
            formData.append(key, payload[key]);
        }

        const response = await instance.post(`${Configuration.api.base}/autocompletar`, formData);
        return response.data;
    },

    async verificarUsuario(payload) {
        const formData = new FormData();
        formData.append('rut', payload.rut);
        formData.append('email', payload.email);

        const response = await instance.post(`${Configuration.api.base}/verificar-datos`, formData);
        return response.data;
    },

    async getURLBackend() {
        const response = await instance.get(`${Configuration.api.base}/url`);
        return response.data;
    },

}
