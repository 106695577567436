import { createVuetify } from 'vuetify';
import { VNumberInput } from 'vuetify/labs/components';

import '@mdi/font/css/materialdesignicons.css';

import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
  icons: {
    iconfont: 'mdi',
  },
  components: {
    ...components,
    VNumberInput,
    directives 
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#5A05FF', 
          secondary: '#3AE374',
          accent: '#82B1FF',
          error: '#C41C30',
          info: '#004CDD',
          success: '#04976A',
          warning: '#F5A300',
        },
      },
      dark: {
        colors: {
          primary: '#5A05FF', 
          secondary: '#3AE374',
          accent: '#82B1FF',
          error: '#C41C30',
          info: '#004CDD',
          success: '#04976A',
          warning: '#F5A300',
        },
      },
    },
  },
});
