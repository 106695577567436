<template>
  <div class="mt-16 not_found">
    <div class="content">
      <h1>Error</h1>
      <h2>La página que buscas no existe o ha sido eliminada</h2>
  
      <v-btn size="large" class="mt-5 text-none" to="/" color="primary">
        Volver al inicio
      </v-btn>
    </div>

    <v-img
      src="/images/404.png"
    />
  </div>
</template>
  
<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
  .not_found {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;

    .content {
      display: inline-block;
      flex-direction: column;
      align-content: center;

    }
    .v-img{
      max-height: 300px !important;
    }
  }

</style>