<template>
    <v-card flat class="purchase__order_card pa-4">
        <template v-slot:title>
            <span class="font-weight-black">Resumen de compra</span>
        </template>
        <v-container class="content_card px-5">
            <v-row>
                <div class="custom__hr"></div>
            </v-row>
            <!-- detalle -->
            <v-row>
                <span>{{ item.name }} {{ `(${ count })` }}</span>
                <v-spacer></v-spacer>
                <span>{{ formatCurrency(item.price) }}</span>
            </v-row>

            <!-- total -->
             <div class="mt-7">
                <v-row>
                    <strong>Total</strong>
                    <v-spacer></v-spacer>
                    <strong>{{ `${ total }` }}</strong>
                </v-row>
             </div>
            
            <v-row>
                <v-btn 
                    @click="continueAction" 
                    class="mt-7 text-none font-weight-bold" 
                    block 
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                >
                    {{ textButton }}
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import api from '@/api';
import { formatCurrency } from '@/helpers/utils';
import { mapGetters } from 'vuex';

export default {
    name:'purchase-order',
    data(){
        return {}
    },
    computed: {
        ...mapGetters('cart', ['item', 'count', 'total', 'step', 'formStepTwo']),
        ...mapGetters('payment', ['customerInfo', 'loading']),
        textButton() {
            switch (this.step) {
                case 1:
                    return 'Continuar compra';
                case 2:
                    return 'Continuar';
                case 3: 
                    return 'Pagar ahora';
                default:
                    return 'Continuar';
            }
        }
    },
    methods: {
        formatCurrency(value) {
            return formatCurrency(value);
        },
        async continueAction(){
            const isPaymentStep = this.step === 3;
            const isFormStep = this.step === 2;

            if (isFormStep) {
                if (this.formStepTwo.isValid) {
                    if(this.customerInfo.autocomplete){
                        const data =  await this.verificarUsuario();
                        if(! data ) return;
                    }

                    this.$store.dispatch('cart/nextStep');

                } else {
                    this.$store.dispatch('cart/updateSubmittedForm', true);
                }
            } else if (isPaymentStep) {
                this.handlePayment();
            } else {
                this.$store.dispatch('cart/nextStep');
            }
        },
        async handlePayment() {
            this.$store.dispatch('payment/handleLoading', true);

            const payload = {
                name: this.customerInfo.name,
                lastname: this.customerInfo.lastname,
                rut: this.customerInfo.rut,
                email: this.customerInfo.email,
                telefono: this.customerInfo.telefono,
                tipo_informe: this.item.id,
                monto_compra: this.count * this.item.price,
                cant_informes: this.count
            }

            try {
                const { status, url, token } = await this.$store.dispatch('payment/startPayment', payload);

                if (status === 1 && url) {
                    // Abre una nueva ventana para el pago
                    const paymentWindow = window.open(url, '_blank'); // deberia manejar esto en el store ?? podria ser, para crear una nueva orden de compra

                    const pollInterval = setInterval(async () => {
                        try {
                            const { status, payment_desc } = await this.$store.dispatch('payment/checkStatus', token);
                            if (paymentWindow.closed && status == 1 && payment_desc == 'pendiente') {
                                clearInterval(pollInterval);
                                this.$store.dispatch('snackbar/showSnack', { text: 'El pago no se ha procesado', type: 'error' });
                                this.$store.dispatch('payment/handleLoading', false);
                                
                                await this.$store.dispatch('payment/startCancelPayment');
                                return;
                            }
    
                            else if(status == 2){
                                clearInterval(pollInterval);
                                setTimeout(() => {
                                    paymentWindow.close();
                                    this.$store.dispatch('payment/handleLoading', false);
                                    this.$store.dispatch('cart/nextStep');
                                }, 2000);
    
                            }
                            else if(status == 3){
                                clearInterval(pollInterval);
                                paymentWindow.close();
                                this.$store.dispatch('payment/handleLoading', false);
                                this.$store.dispatch('snackbar/showSnack', { text: 'Ha ocurrido un error al procesar el pago', type: 'error' });
                            }
                        } catch (error) {
                            this.$store.dispatch('payment/handleLoading', false);
                            console.error('Error al verificar el estado del pago:', error);
                        }
                       
                    }, 5000); 
                } else {
                    console.error('Error en el estado del pago:', status);
                    this.$store.dispatch('payment/handleLoading', false);
                }
            } catch (error) {
                console.error('Error en el proceso de pago:', error);
                this.$store.dispatch('payment/handleLoading', false);
                this.$store.dispatch('snackbar/showSnack', { text: 'Ha ocurrido un error al procesar el pago', type: 'error' });
            }
        },
        async verificarUsuario(){
            this.$store.dispatch('payment/handleLoading', true);

            const payload = {
                rut: this.customerInfo.rut,
                email: this.customerInfo.email,
            }

            try {
                const { status } = await api.verificarUsuario(payload); 
                
                if(status === 1){
                    return true;
                }
                
            } catch (error) {
                const errorMessage = error.message;
                this.$store.dispatch('snackbar/showSnack', { text: errorMessage, type: 'error' });
                return false;
                
            }finally{
                this.$store.dispatch('payment/handleLoading', false);
            }
        }

    }
}
</script>
<style scoped lang="scss">
    //
</style>
