<template>
    <v-card
        class="pa-4 product__order_card"
        elevation="3"
    >
        <template v-slot:title>
            <span><strong>Datos de compra</strong></span>
        </template>

        <v-container class="content_card ">
            <v-row>
                <div class="custom__hr"></div>
            </v-row>
            <v-form class="w-100" ref="form" v-model="formValid" @submit.prevent>
                <v-row class="purchase__info_contact">
                    <v-col cols="12" md="6">
                        <label>Nombre</label>
                        <v-text-field
                            v-model="customerInfo.name"
                            required
                            variant="solo"
                            density="compact"
                            :rules="rules.isEmpty"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>Apellido</label>
                        <v-text-field
                            v-model="customerInfo.lastname"
                            required
                            variant="solo"
                            density="compact"
                            :rules="rules.isEmpty"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>RUT</label>
                        <v-text-field
                            id="rut"
                            v-model="customerInfo.rut"
                            required
                            variant="solo"
                            density="compact"
                            @input="handleRutInput(customerInfo.rut)"
                            :rules="rules.rut"
                            counter="12"
                            maxlength="12"
                            @blur="autoCompleteRequest('rut', customerInfo.rut)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>Teléfono</label>
                        <v-text-field
                            v-model="customerInfo.telefono"
                            required
                            variant="solo"
                            density="compact"
                            :rules="rules.phoneValid"
                            prefix="+56 9"
                            :maxlength="8"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>Correo electrónico</label>
                        <v-text-field
                            id="email"
                            v-model="customerInfo.email"
                            required
                            variant="solo"
                            density="compact"
                            :rules="rules.isEmailValid"
                            @blur="autoCompleteRequest('email', customerInfo.email)"
                            :disabled="customerInfo.autocomplete"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h3>Método de pago</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-radio-group v-model="customerInfo.method_pay" >
                            <v-radio value="flow" color="primary" class="custom__radio">
                                <template v-slot:label>
                                    <v-row justify="space-between" align="center">
                                        <v-col>
                                            Tarjeta de crédito, débito o transferencia bancarias
                                        </v-col>
                                        <v-col cols="4" class="d-flex justify-end">
                                            <img 
                                            alt="flow" 
                                            src="/images/Flow.png"
                                            style="max-width: 100%; height: auto;"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox required :rules="rules.terms" v-model="customerInfo.terms" color="primary">
                            <template v-slot:label>
                                <span>
                                    Acepto los 
                                    <a 
                                        class="linkbtn"
                                        href="/pdf/TerminosyCondiciones.pdf" 
                                        download
                                        @click.stop
                                    >Términos y condiciones</a>
                                </span>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-container> 
    </v-card>
</template>

<script>
import api from '@/api';
import { isRutValid, rutParser } from '@/helpers/utils';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'Step_2',
    data(){
        return {
            formValid: false, 
            rules: {
                isEmpty: [
                    v => {
                        if(!v || v.trim() === '') return 'Campo requerido';
                        else if(v.trim() === '0') return 'Campo requerido';
                        else return true;
                    }
                ],
                noSelect: [
                    v => {
                        if(!v || v == 0 || v == null || v == undefined){
                            return 'Campo requerido'
                        }else{
                            return true
                        }
                    }
                ],
                rut: [
                    (v) => !!v || `RUT es requerido`,
                    (v) => isRutValid(v) || `RUT inválido`
                ],
                isEmailValid: [
                    v => {
                        if (!v || v.trim() === '') {
                            return 'Correo requerido';
                        }
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                        if (!emailRegex.test(v.trim())) {
                            return 'Correo inválido';
                        }
                        return true;
                    }
                ],
                phoneValid: [
                    v => !!v || 'Teléfono requerido',
                    v => /^(\d{8})$/.test(v) || 'Ingrese 8 dígitos numéricos',
                ],
                terms: [
                    v => !!v || 'Debes aceptar los términos y condiciones',
                ]

            },
            callAutocomplete: false,

        }
    },
    computed: {
        ...mapState('payment', ['customerInfo']),
        ...mapGetters('cart', ['formStepSubmitted']),
    },
    methods:{
        formatterRUT(campo, value){
            if(value){
                this.customerInfo[campo] = rutParser(value);
            }
        },
        validateForm() {
            if (this.$refs.form) {
                this.$refs.form.validate();
                this.$store.dispatch('cart/updateSubmittedForm', false);
            }
        },
        async autoCompleteRequest(field, value){
            const payload = {};
            payload[field] = value;

            if(this.$refs.form){
                const fieldInput = this.$refs.form.items.filter(input => input.id == field);
                
                if(fieldInput[0].isValid){
                    try {
                        const { status, data } = await api.autoCompleteFields(payload);
                        
                        if(status === 1){
        
                            const { 
                                name, 
                                lastname, 
                                email, 
                                telefono, 
                                rut 
                            } = data;
                            
                            const rutFormatted = rutParser(rut);
                            
                            this.$store.dispatch('payment/updDataCustomer', {
                                name: name,
                                lastname: lastname,
                                email: email,
                                telefono: telefono,
                                rut: rutFormatted,
                                autocomplete: true
                            });

                            this.callAutocomplete = true;
                        }
        
                    } catch (error) {
                        console.log(error);
                    }

                }
            }
        },
        handleRutInput(value) {
            this.formatterRUT('rut', value);

            if (this.callAutocomplete) {
                this.handleRutChange();
            }
        },
        
        handleRutChange() {
            this.$store.dispatch('payment/updDataCustomer', {
                email: '',
                autocomplete: false
            });
            this.callAutocomplete = false;
        },
    },
    watch:{
        'formValid': {
            handler(val){
                this.$store.dispatch('cart/updateStatusForm', val);
            },
            immediate: true, 
            deep: true,
        },
        formStepSubmitted(newValue) {
            if (newValue) {
                this.validateForm(); 
            }
        },
        'customerInfo.email'(newVal) {
            this.$store.dispatch('payment/updDataCustomer', {
                email: newVal.toLowerCase()
            });
        }

    }

}
</script>

<style lang="scss" scoped>
    .purchase__info_contact{
        label{
            font-weight: 600;
        }
    }

    .custom__radio{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $purple-600;
        color: #3d3d3d !important;
        width: 100%;
    }
    ::v-deep.v-selection-control .v-label.v-label--clickable{
        width: 100% !important;
        opacity: 1 !important;
    }

    ::v-deep label.v-label.v-label--clickable{
        opacity: 1 !important;
    }
   


    .linkbtn{
       color: $purple-600;
       text-decoration: none;
       font-weight: 600;
       opacity: 1;
    }


</style>


