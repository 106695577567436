export const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
    }).format(value);
}

export const isRutValid = (rut_complete) => {
    let clean_rut = rut_complete.replace(/[^k0-9]/gi, "").split("");

    let rut = clean_rut.slice(0, -1).join("");
    let dv = clean_rut
        .slice(clean_rut.length - 1, clean_rut.length)
        .toString();

    let i = 2;
    let sum = 0;

    rut
        .split("")
        .reverse()
        .forEach((el) => {
        if (i == 8) {
            i = 2;
        }
        sum += parseInt(el) * i;
        ++i;
        });

    let dvr = 11 - (sum % 11);

    if (dvr == 11) dvr = 0;
    if (dvr == 10) dvr = "K";

    return dvr.toString().toUpperCase() == dv.toUpperCase();
};

export const rutParser = (rut) => {
    if(rut !== null){
      rut = rut.replace(/[^k0-9]/gim, "").split("");
      return rut.length > 1
        ? `${parseInt(rut.slice(0, -1).join("")).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}-${
            rut[rut.length - 1]
          }`
        : rut.join("");
    }
};
