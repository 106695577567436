<template>
    <v-card class="pa-4 product__order_card" elevation="3">
        <template v-slot:title>
            <span><strong>Confirmación de compra</strong></span>
        </template>

        <v-container class="content_card px-5">
            <v-row>
                <div class="custom__hr"></div>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h3>Datos del comprador</h3>
                </v-col>
            </v-row>
            <v-row v-for="(data, index) in customer_data" :key="index">
                <v-col cols="12" class="customer__data" v-if="data.value">
                    <span class="label__title">{{ data.label }}</span>
                    <span class="label__info">{{ data.value }}</span>
                </v-col>
            </v-row>
        </v-container> 
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Step_3',
    computed: {
        ...mapGetters('payment', ['customerInfo']),
        customer_data() {
            return [
                { label: 'Nombre', value: `${this.customerInfo.name} ${this.customerInfo.lastname}` },
                { label: 'Rut', value: this.customerInfo.rut },
                { label: 'Correo', value: this.customerInfo.email },
                { label: 'Teléfono', value: this.customerInfo.telefono },
                { label: 'Método de pago', value: 'Flow - Tarjeta de crédito, débito o transferencia bancaria' },
            ];
        }
    }
}
</script>

<style lang="scss" scoped>
.customer__data {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    padding: 8px !important;
    .label__title {
        font-weight: 600;
    }
    
}
@include breakpoint(sm, min) {
    .customer__data {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;

    }
}
@include breakpoint(md, min) {
    .customer__data {
        display: grid;
        grid-template-columns: 30% 70%;
        width: 100%;
        padding: 8px !important;

        .label__info {
            display: inline-flex;
            justify-self: flex-end;  
            text-align: end; 
        }
    }
}
@include breakpoint(lg, min) {
    .customer__data {
        display: grid;
        grid-template-columns: 30% 70% !important;
        width: 100%;
        padding: 8px !important;

        .label__info {
            display: inline-flex;
            justify-self: flex-end; 
            text-align: end; 
        }
    }
}
</style>
