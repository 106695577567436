
const icons = [{event: 'success', icon:'check-circle'}, {event: 'error', icon: 'close-circle-outline'}, {event: 'info', icon: 'information-outline'}]
const getIcon = (evento = null) => {
    const icon = icons.find(({ event }) => event === evento);
    return icon ? icon.icon : null;
};

export default {
    namespaced: true,
    state:{
        snack: {
            text: null,
            type: null,
            icon: null,
        },
    },
    getters: {
        getSnack: state => state.snack,
    },
    mutations: {
        SET_SNACK(state, payload) {
            state.snack.text = payload.text;
            state.snack.type = payload.type;
            state.snack.icon = getIcon(payload.type);
        },
    },
    actions: {
        showSnack({ commit }, payload) {
            commit('SET_SNACK', payload);

            setTimeout(() => {
                commit('SET_SNACK', {text:null, type: null} );
            }, 3000)
        },
        closeSnack({ commit }){
            commit('SET_SNACK', {text:null, type: null} );
        }
    },
};