import api from "@/api";

export default {
    namespaced: true,
    state: {
        customerInfo: {
          name: '',
          lastname: '',
          rut: '',
          email: '',
          telefono: '',
          method_pay: 'flow',
          terms: false,
          autocomplete: false,
        },
        loading: false,
        n_orden: null, 
        paymentInfo: null,
        f_token: null,
      },
    mutations: {
        SET_BUYER_INFO(state, customerInfo) {
          state.customerInfo = customerInfo;
        },
        CLEAR_CHECKOUT(state) {
          state.customerInfo = { 
            name: '',
            rut: '',
            email: '',
            telefono: '',
            method_pay: 'flow', 
            terms: false,
            autocomplete: false,
          };
          state.loading = false;
          state.n_orden =  null; 
          state.paymentInfo =  null;
          state.f_token =  null;
        },
        SET_LOADING(state, isLoading) {
          state.loading = isLoading;
        },
        SET_ORDEN_DATA(state, payload){
          state.n_orden = payload;
        },
        SET_FLOW_TOKEN(state, payload){
          state.f_token = payload;
        },
        SET_PAYMENT_INFO(state, payload){
          state.paymentInfo = payload;
        },
        SET_CUSTOMER_INFO(state, payload){
          state.customerInfo = {
            ...state.customerInfo,
            ...payload
          };
        }
    },
    actions: {
        updateBuyerInfo({ commit }, buyerInfo) {
          commit('SET_BUYER_INFO', buyerInfo);
        },
        resetCheckout({ commit }) {
          commit('CLEAR_CHECKOUT');
        },
        handleLoading({ commit }, status){
          commit('SET_LOADING', status); 
        },
        updOrdenData({ commit }, orden){
          commit('SET_ORDEN_DATA', orden); 
        },
        async startPayment({ commit, rootState }, payload) {
          try {
            // 1. Crear la orden
            const { data } = await api.createCompraInforme(payload);
            commit('SET_ORDEN_DATA', data.orden);
            
            // 2. Crear el pago
            const paymentPayload = {
              orden_compra: data.orden,
              monto: payload.monto_compra,
              descripcion: `Pago por ${ rootState.cart.item.name}`,
              email_cliente: payload.email
            };

            const paymentData = await api.createPayment(paymentPayload);
            commit('SET_FLOW_TOKEN', paymentData.token); // Guardar la información del pago
            
            return paymentData;
      
          } catch (error) {
            console.error(error);
            throw error;
          } 
        },
        async checkStatus({ commit }, payload){
          try {
            const data = await api.getStatePayment(payload);
            commit('SET_PAYMENT_INFO', { status: data.int_status, payment_desc: data.payment_status });
            return { status: data.int_status, payment_desc: data.payment_status };
          } catch (error) {
              console.error('Ha ocurrido un error ' +  error);
          }
        }, 
        async startCancelPayment({ commit, state }){
          try {
            await api.cancelPayment(state.n_orden);
            
            commit('SET_ORDEN_DATA', null); 
            commit('SET_FLOW_TOKEN', null);

          } catch (error) {
              console.error('Ha ocurrido un error ' +  error);
          }
        },
        updDataCustomer({commit}, payload){
          commit('SET_CUSTOMER_INFO', payload)
        },
    },
    getters: {
      customerInfo: state => state.customerInfo,
      paymentStatus: state => state.paymentStatus,
      loading: state => state.loading,
      n_orden: state => state.n_orden,
    }
}