export const Configuration = {
    api: {
      base: process.env.VUE_APP_API + "/api",
      sii: process.env.VUE_APP_API_CRM + "/api/sii/terceros?rut=",
    },
    Refresh: {
      Timeout: 10 * 1000
    },
    Redirection: {
      timeout: 1 * 1000 // Seconds * Milliseconds
    },
};