<template>
  <v-card
    class="pa-4 product__order_card"
    elevation="3"
  >
    <template v-slot:title>
      <span><strong>Detalle de producto</strong></span>
    </template>

    <v-container class="content_card">
      <v-row>
          <div class="custom__hr"></div>
      </v-row>

      <v-row>
        <div class="purchase__info">
          <template v-if="! isMobile">
            <div class="table">
              <div><strong>Producto</strong></div>
              <div><strong>Cantidad</strong></div>
              <div><strong>Subtotal</strong></div>
              <div><strong></strong></div>
            </div>
            <div class="table">
              <div>{{ item.name }}</div>
              <div>
                <v-number-input
                  v-model="countItem"
                  controlVariant="split"
                  density="compact"
                  :min="1"
                  variant="solo"
                  :width="isMobile ? '100%' : '80%'"
                  hideDetails
                ></v-number-input>
              </div>
              <div>{{ `${ total }` }}</div>
            </div>
          </template>
          <template v-else>
            <div class="tableMobile">
              <div><strong>Producto</strong></div>
              <div>{{ item.name }}</div>
            </div>
            <div class="tableMobile">
              <div><strong>Cantidad</strong></div>
              <div>
                <v-number-input
                  v-model="countItem"
                  controlVariant="split"
                  density="compact"
                  :min="1"
                  variant="solo"
                  hideDetails
                ></v-number-input>
              </div>
            </div>
            <div class="tableMobile">
              <div><strong>Subtotal</strong></div>
              <div>{{ `${ total }` }}</div>
            </div>
          </template>
        </div>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-expansion-panels flat tile static >
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span>Detalle del producto</span>
              <template v-slot:actions="{ expanded }">
                <v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              {{ `${item.name}. El documento contiene:` }} <br>
              <ul v-for="desc of item.descriptions" :key="desc.name">
                <li :key="desc.name">
                  {{ desc }}
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { formatCurrency } from '@/helpers/utils';
import { mapGetters } from 'vuex';
export default{
    name: 'Step_1',
    data(){
        return{
            isMobile: true, 
            
        }
    },
    computed: {
      ...mapGetters('cart', ['item', 'count', 'total']),
      subtotalComputed(){
        return formatCurrency(this.product.count * this.product.price);
      },
      countItem: {
        get(){
          return this.$store.getters['cart/count'];
        },
        set(value) {
          this.$store.dispatch('cart/updateCount', value);
        }
      }
    },
    methods: {
        onResize() {
            if (window.innerWidth <= 600) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize(); 
    },
}
</script>

<style lang="scss">

.purchase__info{
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
    
  .table{
    display: grid;
    grid-template-columns: 30% 40% 30%;
    gap: 10px;
    align-items: center;
  }

  .tableMobile{
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
    align-items: center;
  }
}



.v-input__control .v-field__append-inner {
  .v-number-input__control{
    
    hr.v-divider--vertical{
      display: none !important;
    } 

    button .v-btn__content{
      color: $purple-600;
    }
   
  }
  
}

.v-input__control .v-field__prepend-inner{
  .v-number-input__control{
    hr.v-divider--vertical{
      display: none !important;
    }
    
    button .v-btn__content{
      color: $purple-600;
    }

    button:disabled{
      background-color: transparent !important;
      opacity: 0.1;
      .v-btn__content{
        color: $gray-800;
      }
    }
  }
  
}

.v-expansion-panels {
  .v-expansion-panel{
    .v-expansion-panel-title{
      padding: 0px;
      justify-content: left !important;
      color: $purple-600;
      font-weight: 600;

      .v-expansion-panel-title__icon{
        margin-inline-start: 5px;
      }
    }
  }
  .v-expansion-panel-title__overlay{
    display: none !important;
  }
}

</style>