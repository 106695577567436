import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import { cart, payment, snackbar } from './modules';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cart,
    payment,
    snackbar
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ]
})
