<template>
    <v-container class="d-flex align-center justify-center">
        <v-card class="complete__steps_card" elevation="3">
            <v-container>
                <v-row justify="center">
                    <img 
                        class="img" 
                        alt="check" 
                        src="/images/Check.png"
                        
                    />
                </v-row>
                <v-row  justify="center">
                    <h2 class="mb-2 text-center">Estamos generando tus informes</h2>
                </v-row>
                <v-row justify="center" class="text-center">
                    <p>Esto puede tardar unos minutos. Cuando estén listos te los enviaremos a tu correo.</p>
                    <strong>Revisa los informes generados registrándote en nuestra plataforma.</strong>
                </v-row>
                <v-row justify="center">
                    <v-btn class="mt-7 text-none font-weight-bold" color="primary" :href="url + 'cliente/register'" >
                        Regístrate
                    </v-btn>
                </v-row>
                <v-row justify="center" >
                    <p class="my-5">
                        Si ya tienes cuenta sólo debes
                        <a :href="url + 'cliente/login'"  class="mt-7 text-none font-weight-bold" style="text-decoration: none !important" color="primary">iniciar sesión</a>
                    </p>
                </v-row>
            </v-container>
    
        </v-card>
    </v-container>
</template>

<script>
import api from '@/api';

export default {
    name: 'Step_4',
    data(){
        return {
            url: '',
        }
    },
    components: {

    },
    async mounted(){
        const {status, data} = await api.getURLBackend();

        if(status === 1) this.url = data; 
    }
}
</script>

<style lang="scss" scoped>
.img{
    max-width: 82px;
    max-height: 82px;
    margin: auto;
}
</style>