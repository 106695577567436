<template>
    <v-responsive>
        <v-container>
            <v-form ref="formStep4" v-model="formValid" @submit.prevent="submitForm">
                <v-row class="my-2">
                    <h3>Ingresa los datos de {{ `${ruts.length == 1 ? 'la empresa a evaluar' : 'las empresas a evaluar' }` }}</h3>
                </v-row>
                <v-row v-for="(input, index) of ruts" :key="index">
                    <v-col cols="12">
                        <label>RUT</label>
                        <v-text-field
                            v-model="ruts[index].rut"
                            required
                            variant="solo"
                            density="compact"
                            @input="formatterRUT(index,'rut', ruts[index].rut)"
                            :rules="rules.rut"
                            counter="12"
                            maxlength="12"
                            @blur="log(index, ruts[index].rut)"
                        >
                        <template v-slot:append-inner>
                            <v-icon v-if="ruts[index].loadDataSII" class="spin" color="success" icon="mdi-cached" ></v-icon>
                            <v-icon v-else color="success">{{ ruts[index].rutOk ? 'mdi-check-circle' : true }}</v-icon>
                        </template>
                        </v-text-field>
                    </v-col>
                    <transition name="fade-slide">
                        <v-col cols="12" v-if="ruts[index].razon_social !== ''">
                            <label>Razón social</label>
                            <v-text-field
                                v-model="ruts[index].razon_social"
                                required
                                variant="solo"
                                density="compact"
                                :rules="rules.isEmpty"
                                disabled
                            ></v-text-field>
                        </v-col>
                    </transition>
                    <v-col cols="12" v-if="item.id === 2">
                        <label>Correo electrónico empresa</label>
                        <v-text-field
                            v-model="ruts[index].email_empresa"
                            required
                            variant="solo"
                            density="compact"
                            :rules="rules.isEmailValid"
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="ruts.length > 1">
                        <div class="custom__hr"></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            type="submit" 
                            class="mt-7 text-none font-weight-bold" 
                            block 
                            color="primary"
                            :disabled="! allFieldsIsValid"
                            :loading="loadingBtn"
                        >
                            Generar {{ `${ruts.length == 1 ? 'informe' : 'informes' }`  }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-responsive>
</template>

<script>
import api from '@/api';
import { Configuration } from '@/config';
import { isRutValid, rutParser } from '@/helpers/utils';
import { mapGetters } from 'vuex';

export default {
    name:'input-rut',
    data(){
        return {
            ruts: [],
            rules: {
                isEmpty: [
                    v => {
                        if(!v || v.trim() === '') return 'Campo requerido';
                        else if(v.trim() === '0') return 'Campo requerido';
                        else return true;
                    }
                ],
                rut: [
                    (v) => !!v || `RUT es requerido`,
                    (v) => isRutValid(v) || `RUT inválido`
                ],
                isEmailValid: [
                    v => {
                        if (!v || v.trim() === '') {
                            return 'Correo requerido';
                        }
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                        if (!emailRegex.test(v.trim())) {
                            return 'Correo inválido';
                        }
                        return true;
                    }
                ],
            },
            formValid: false,
            loadingBtn: false
        }
    },
    computed: {
        ...mapGetters('cart', ['count', 'item']),
        ...mapGetters('payment', ['n_orden']),
        allFieldsIsValid(){

            return this.ruts.every((rut) => rut.rutOk === true && ( this.item.id === 2 ? rut.email_empresa.length > 0 : true));
        }
    },
    beforeMount(){
        this.addClientes();
    },
    methods: {
        formatterRUT(index,campo, value){
            if(value){
                this.ruts[index][campo] = rutParser(value);
            }
        },
        crearNuevoCliente(){
            const cliente = {
                rut: '',
                razon_social: '',
                loadDataSII: false,
                countCallAPI: 0,
                rutOk: false,
                requestAPI: false,
                email_empresa: ''
            }

            return cliente;
        },
        addClientes(){
            for(let i = 0; i < this.count; i++){
                this.ruts.push(this.crearNuevoCliente());
            }
        },
        log(index, val) {
            if(val != ''){
                // @ts-ignore
                if(! this.ruts[index].requestAPI){
                    // @ts-ignore
                    this.callApi(index, val);
                }
            }
        },
        callApi(index, val){
            val = rutParser(val);
            this.$data.dni = val;
            if(! this.ruts[index].loadDataSII){
                this.ruts[index].loadDataSII = true;
            }
			
            
            if (isRutValid(val.toUpperCase()) && val.length >= 11) {
                this.ruts[index].requestAPI = true;
                fetch(`${Configuration.api.sii}${val.toUpperCase()}`)
                .then((response) => {
                    if (!response.ok) {
                        // Si la respuesta no es "ok" (status no es 2xx), lanzamos un error
                        throw new Error(`Error en la API: ${response.status}`);
                    }
                    return response.json(); // Transformamos la respuesta a JSON
                })
                .then((data) => {
                    // Aquí manejamos los datos devueltos por la API
                    this.ruts[index].loadDataSII = false;
                    this.ruts[index].rutOk = true;
                    this.ruts[index].countCallAPI = 0;
                    if (this.ruts[index].requestAPI) {
                        this.ruts[index].requestAPI = false;
                    }
                    
                    const { razon_social, presenta_inicio_actividades } = data;

                    if(presenta_inicio_actividades === 'NO') throw new Error("El RUT no presenta inicio de actividades");
                    
                    this.ruts[index].razon_social = razon_social;
                    
                })
                .catch((error) => {
                    if (error.message === "El RUT no presenta inicio de actividades") {
                        // Manejo del caso específico cuando no presenta inicio de actividades
                        this.ruts[index].rut = '';
                        this.ruts[index].razon_social = '';
                        this.ruts[index].loadDataSII = false;
                        this.ruts[index].rutOk = false;
                        this.ruts[index].requestAPI = false;
                        this.ruts[index].countCallAPI = 0;

                        this.$store.dispatch('snackbar/showSnack', { text: error.message, type: 'error' });

                        // Lanzar un mensaje de error personalizado
                        // EventBus.$emit("snack-error", 'El RUT no presenta inicio de actividades');
                    } else {
                        // Si el error es diferente, continuar con el manejo estándar
                        if (this.ruts[index].countCallAPI === 4) {
                            this.ruts[index].countCallAPI = 0;
                            // Asumimos que es una persona sin GIRO
                            this.ruts[index].rut = '';
                            this.ruts[index].razon_social = '';
                            this.ruts[index].loadDataSII = false;
                            this.ruts[index].rutOk = false;
                            this.ruts[index].requestAPI = false;
                            
                            this.$store.dispatch('snackbar/showSnack', { text:'No es posible traer tus datos del SII en estos momentos', type: 'error' });
                        } else {
                            this.ruts[index].countCallAPI++;
                            // Reintentamos la llamada
                            this.callApi(index, val);
                        }
                    }
                });

            }
        },
        async submitForm(){
            if (this.$refs.formStep4 && this.$refs.formStep4.isValid) {
                this.loadingBtn = true;
                const payload = this.createPayload();
                
                try {
                    const { status } = await api.updateDataOrder(payload);
                    if(status === 1){
                        this.$store.dispatch('cart/flowComplete');
                    }else{
                         this.$store.dispatch('snackbar/showSnack', { text: 'Ha ocurrido un error', type: 'error' });
                    }
                    
                } catch (error) {
                    console.error(error);
                    this.$store.dispatch('snackbar/showSnack', { text: 'Ha ocurrido un error', type: 'error' });
                }finally{
                    this.loadingBtn = false;
                }
                

            }
        },
        createPayload(){

            let payload = {};

            payload.ruts = this.ruts.map( empresa => {
                return {
                    razon_social: empresa.razon_social,
                    rut: empresa.rut,
                    email: empresa.email_empresa
                }
            });

            payload.orden = this.n_orden;

            return payload;
        }
    },

}
</script>
<style scoped lang="scss">

label{
    font-weight: 600;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}
</style>
