<template>
   <v-app-bar :elevation="2">
        <template v-slot:prepend>
            <a href="https://checkempresa.cl" rel="noopener noreferrer">
                <img 
                    class="logo" 
                    alt="CheckEmpresa logo" 
                    style="padding-right: 20px;" 
                    src="/images/LogoCE.png"
                />
            </a>
        </template>
    </v-app-bar>
</template>
<script>
export default {
    name: 'NavBarCheckEmpresa',
    data() {
        return {
            showMenu: false,
            isMobile: true,
        };
    },
    methods: {
        onResize() {
            if (window.innerWidth <= 1270) { // Verificación de ancho de pantalla
                this.isMobile = true;
            } else {
                this.isMobile = false;
                this.showMenu = false;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize(); // Llama a la función para configurar el estado inicial
    },
};
</script>
<style lang="scss" scoped>
.logo {
    @media (max-width: 960px) {
        height: 40px;
    }
    @media (min-width: 960px) {
        height: 50px;
    }
}
</style>