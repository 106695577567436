import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';

const app = createApp(App);

//TODO: QUITAR
// Forzar el uso de Vue DevTools, incluso en producción (solo para debugging)
app.config.devtools = true;

app.use(store).use(router).use(vuetify).mount('#app');
