<template>
    <v-alert
        v-model="alert_payment"
        close-label="close"
        closable
        type="success"
        class="v-alert_custom my-3"
        variant="tonal"
        icon="mdi-check-circle-outline"
        prominent
        
    >
        <v-alert-title>
            <strong>Pago exitoso</strong>
        </v-alert-title>
        Enviamos el comprobante a tu correo electrónico
    </v-alert>
    <v-card class="pa-4 product__order_card" elevation="3">
        <template v-slot:title>
            <span><strong>Generar informe</strong></span>
        </template>

        <v-container class="content_card px-5">
            <v-row>
                <div class="custom__hr"></div>
            </v-row>
            <!-- ALERTA -->
            <v-row>
                <span class="check-soft_badge_info">
                    <v-icon icon="mdi-information" style="margin-right: 5px;"></v-icon>
                    <span>
                        El rut ingresado debe contar con inicio de actividades en el Servicio de Impuestos Internos
                    </span>
                </span>
            </v-row>
            <!-- INPUTS -->
            <input-rut />

        </v-container> 
    </v-card>
</template>

<script>
import { InputRut } from '../components'
export default {
    name: 'Step_4',
    data(){
        return {
            alert_payment: true, 
        }
    },
    components: {
        InputRut
    }
}
</script>

<style lang="scss">
    .v-alert_custom{
        border: 1px solid $success-400;
        border-radius: 10px !important;
        background-color: $success-100 !important;
    }
</style>