<template>
    <div class="top__snack">
        <v-card
            v-if="snack.text"
            class="round__snack"
            :class="`custom__snack-${snack.type}`"
            elevation="2"
            outlined
        >
            <div class="d-flex align-center flex-row">
                <v-icon :icon="`mdi-${snack.icon}`" size="24" class="mr-3" />
                <div class="d-flex align-center">
                    <span class="rc_textAlert">{{ snack.text }}</span>
                </div>
                <v-spacer />
                <v-btn
                    icon="mdi-close"
                    variant="text"
                    @click="close"
                    class="rc_textAlert"
                />
            </div>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'snack-bar',
    computed: {
        ...mapGetters('snackbar', ['getSnack']),
        snack() {
            return this.getSnack;
        }
    },
    methods: {
        ...mapActions('snackbar', ['closeSnack']),
        close() {
            this.closeSnack();
        }
    }
};
</script>
<style lang="scss" scoped>

    .top__snack{
        position: fixed;
        right: 15px;
        z-index: 9999;
        top: 100px;
        width: max-content;

        @media (max-width: 600px) {
            left: 10px !important;
            right: 10px !important;
            width: auto !important;
        }
    }
    
    .round__snack{
        padding: 10px 20px;
        border-radius: 15px;
        .rc_textAlert{
            font-weight: 500;
        }
        span.rc_textAlert{
            max-width: 250px;

            @media (max-width: 600px) {
                max-width: 100%; 
                white-space: normal;
            }
        }
    }

</style>

