<template>
    <v-app>
      <v-main>
        <router-view />
        <Snackbar />
      </v-main>
    </v-app>
</template>

<script>
import { Snackbar } from './ui/components';

export default {
  name: 'App',
  components: {
    Snackbar
  }
}
</script>
<style lang="scss">
#app{
  font-family: 'Inter', sans-serif;
}
</style>
