import { formatCurrency } from "@/helpers/utils";
import { informes } from "@/purchase-event/data/informes";

export default {
    namespaced: true, 
    state: {
        item: informes.find((informe) => informe.id === 1),
        count: 1,
        total: informes.find(informe => informe.id === 1).price,
        step: 1,
        formStep:{
            isValid: false,
            isSubmitted: false,
        }
    },
    mutations: {
        SET_ITEM(state, item) {
            state.item = item;
            this.commit('cart/UPDATE_TOTAL');
        },
        SET_COUNT(state, count) {
            state.count = count;
            this.commit('cart/UPDATE_TOTAL');
        },
        UPDATE_TOTAL(state) {
            state.total = state.item.price * state.count;
        },
        CLEAR_CART(state) {
            state.item = informes.find(informe => informe.id === 1);
            state.count = 1;
            state.total = state.item.price;
            state.formStep.isValid = false;
            state.formStep.isSubmitted = false;
        },
        SET_STEP(state){
            state.step = state.step + 1;
        },
        SET_STEP_COMPLETE(state){
            state.step = 5;
        },
        SET_CUSTOM_STEP(state, step){
            state.step = step
        },
        SET_VALID_FORM(state, status){
            state.formStep.isValid = status;
        },
        SET_SUBMITTED_FORM(state, status){
            state.formStep.isSubmitted = status;
        },
    },
    actions: {
        addItem({ commit }, slugItem) {
            const item = informes.find(informe => informe.slug === slugItem);
            if (item) {
                commit('SET_ITEM', item);
            }
        },
        updateCount({ commit }, count) {
            commit('SET_COUNT', count);
        },
        clearCart({ commit }) {
            commit('CLEAR_CART');
        },
        nextStep({ commit }) {
            commit('SET_STEP');
        },
        flowComplete( { commit } ){
            commit('SET_STEP_COMPLETE');
            commit('CLEAR_CART');
        },
        backFlow({ commit }, count){
            commit('SET_CUSTOM_STEP', 4);
            commit('SET_COUNT', count);
        },
        updateStatusForm({ commit }, state){
            commit('SET_VALID_FORM', state);
        },
        updateSubmittedForm({ commit }, state){
            commit('SET_SUBMITTED_FORM', state);
        }
        
    },
    getters: {
        item: state => state.item,
        count: state => state.count,
        total: state => formatCurrency(state.total),
        step: state => state.step,
        formStepTwo: state => state.formStep,
        formStepSubmitted: state => state.formStep.isSubmitted
    }
}