<template>
    <Navbar /> 
    <main class="d-flex align-center justify-center" >
        <v-container class="px-md-16">
            <router-view />
        </v-container>
    </main>
</template>

<script>
import Navbar from '../components/Navbar.vue';

export default {
    name: 'LayoutCheckempresa',
    components: {
        Navbar
    },
}
</script>

<style lang="scss" scoped>
</style>